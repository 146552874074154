import getConfig from "highline/config/application"
import Rollbar from "highline/utils/rollbar"
import {
  affirmCheckoutModalOpened,
  affirmCheckoutModalClosed,
  affirmCheckoutFailed,
} from "highline/redux/actions/billing_information_actions"
import { submitOrderCompleteAsync } from "highline/redux/actions/order_actions"
import { walletAffirmCheckoutSucceeded } from "highline/redux/actions/wallet_actions"

/*
 * Iniliaze Affirm payments using the provided function from Affirm documentation:
 * https://docs.affirm.com/affirm-developers/docs/checkout-web
 */
export default function initAffirm() {
  const { affirmApiKey: public_api_key, affirmScriptUrl: script } = getConfig()
  if (typeof window != "undefined" && !window.affirm) {
    // eslint-disable-next-line
    return (function (m, g, n, d, a, e, h, c) {
      var b = m[n] || {},
        k = document.createElement(e),
        p = document.getElementsByTagName(e)[0],
        l = function (a, b, c) {
          return function () {
            a[b]._.push([c, arguments])
          }
        }
      b[d] = l(b, d, "set")
      var f = b[d]
      b[a] = {}
      b[a]._ = []
      f._ = []
      b._ = []
      b[a][h] = l(b, a, h)
      b[c] = function () {
        b._.push([h, arguments])
      }
      a = 0
      for (
        c = "set add save post open empty reset on off trigger ready setProduct".split(" ");
        a < c.length;
        a++
      )
        f[c[a]] = l(b, d, c[a])
      a = 0
      for (c = ["get", "token", "url", "items"]; a < c.length; a++) f[c[a]] = function () {}
      k.async = !0
      k.src = g[e]
      p.parentNode.insertBefore(k, p)
      delete g[e]
      f(g)
      m[n] = b
    })(window, { public_api_key, script }, "affirm", "checkout", "ui", "script", "ready", "jsReady")
  }
}

export const createAffirmCheckoutObject = (state) => {
  const order = state.get("order")

  const { email, totalNumeric, taxTotalNumeric, items, number, shippingRate } = order.toJS()

  const shippingAddress = order.get("address")
  const shipping = createAffirmAddress(shippingAddress, email)
  const isBillingSameAsShipping = state.getIn(["billingInformation", "isSameAsShippingAddress"])
  const billingAddress = state.getIn(["billingInformation", "address"])
  const billing = isBillingSameAsShipping ? shipping : createAffirmAddress(billingAddress, email)

  const baseUrl = window.location.origin

  const merchant = {
    name: "Bonobos",
    user_cancel_url: `${baseUrl}/checkout/details`,
    user_confirmation_url: `${baseUrl}/checkout/details`,
    user_confirmation_url_action: "POST",
  }

  return {
    billing,
    items: createAffirmItems(items),
    merchant,
    metadata: {
      mode: "modal",
    },
    order_id: number,
    shipping,
    shipping_amount: convertToCents(shippingRate.totalNumeric),
    tax_amount: convertToCents(taxTotalNumeric),
    total: convertToCents(totalNumeric),
  }
}

const createAffirmAddress = (address, email) => {
  return {
    address: {
      city: address.get("city"),
      country: address.getIn(["country", "code"]),
      line1: address.get("address1"),
      line2: address.get("address2"),
      state: address.getIn(["region", "code"]),
      zipcode: address.getIn(["postalCode", "code"]),
    },
    email,
    name: {
      first: address.get("firstName"),
      last: address.get("lastName"),
    },
    phone_number: address.get("phone"),
  }
}

export const createAffirmItems = (items) => {
  return items.map((item) => {
    return {
      display_name: item.name,
      item_image_url: item.image,
      item_url: item.path,
      qty: item.quantity,
      sku: item.sku,
      unit_price: convertToCents(item.discountedTotalNumeric),
    }
  })
}

export const handleAffirmCheckoutProcess =
  (checkoutPayload, orderNumber) => async (dispatch, getState) => {
    dispatch(affirmCheckoutModalOpened())
    affirmUiReady(() => {
      window.affirm.checkout(checkoutPayload).open({
        onFail: (error) => {
          dispatch(affirmCheckoutModalClosed())
          if (error.reason !== "canceled") {
            dispatch(affirmCheckoutFailed())
            Rollbar.error("Affirm Checkout Failed", orderNumber)
          }
        },
        onSuccess: (checkout) => {
          dispatch(affirmCheckoutModalClosed())
          if (getState().getIn(["wallet", "isActionDialogOpen"])) {
            dispatch(walletAffirmCheckoutSucceeded())
          }
          dispatch(submitOrderCompleteAsync(checkout.checkout_token))
        },
      })
    })
  }

export const affirmHandleFailureAsync = () => async (dispatch, getState) => {
  window &&
    window.affirm &&
    window.affirm.ui.ready(() => {
      window.affirm.ui.error.on("close", () => {
        // For unknown reason, Affirm fires an increasing amount
        // of errors during checkout if multiple errors happen, with
        // no context provided about the errors. This check, based
        // on the state value, is in place to only dispatch the actions once.
        if (getState().getIn(["billingInformation", "isAffirmModalOpen"])) {
          dispatch(affirmCheckoutModalClosed())
          dispatch(affirmCheckoutFailed())
        }
      })
    })
}

export const affirmUiReady = (callback) => {
  window && window.affirm && window.affirm.ui.ready() && callback()
}

export const getEstimate = (total, handleEstimateResponse) => {
  const { affirmAPR, affirmMonths } = getConfig()
  const options = {
    amount: convertToCents(total),
    apr: affirmAPR,
    months: affirmMonths,
  }
  window.affirm.ui.payments.getEstimate(options, handleEstimateResponse)
}

export const convertToCents = (price) => {
  return Math.round(parseFloat(price) * 100)
}

export const getNumericPrice = (price) => {
  return price.replace("$", "")
}

import { LINE_ITEM_MESSAGE } from "highline/utils/preorder"

const DIGITAL_GIFT_CARD_SLUG = "bonobos-digital-gift-card"
const PHYSICAL_GIFT_CARD_SLUG = "physical-gift-card"

export const hasOnlyDigitalGiftCard = (items) =>
  items && items.size > 0 && items.every((item) => item.get("slug") === DIGITAL_GIFT_CARD_SLUG)

export const containsGiftCard = (items) => {
  return items.some((item) => item.get("isGiftCard") === true)
}

export const containsDigitalGiftCard = (lineItems) => {
  return lineItems.some((item) => item.get("slug") === DIGITAL_GIFT_CARD_SLUG)
}

export const containsDigitalOrPhysicalGiftCard = (lineItems) => {
  return lineItems.some(
    (item) =>
      item.get("slug") === DIGITAL_GIFT_CARD_SLUG || item.get("slug") === PHYSICAL_GIFT_CARD_SLUG
  )
}

export const getLineItemProps = (lineItem) => ({
  bundleDiscount: lineItem.get("bundleDiscount"),
  bundles: lineItem.get("discountedBundles"),
  description: lineItem.get("description"),
  description2: lineItem.get("description2"),
  disableQuantityAdjustment: !lineItem.get("canAdjustQuantity"),
  discountedTotal: lineItem.getIn(["amount", "discounted"]),
  finalSale: lineItem.get("finalSale"),
  isFielderProduct: lineItem.get("isFielderProduct"),
  inStock: lineItem.get("inStock"),
  itemId: lineItem.get("itemId"),
  limitedQuantity: lineItem.get("limitedQuantity"),
  lineItemId: lineItem.get("id"),
  link: lineItem.get("path"),
  message: lineItem.get("isPreorder") ? LINE_ITEM_MESSAGE : "",
  name: lineItem.get("name"),
  newQuantity: lineItem.get("new_quantity"),
  price: lineItem.getIn(["amount", "full"]),
  quantity: lineItem.get("quantity"),
  selectedOptions: lineItem.get("options"),
  sku: lineItem.get("sku"),
  slug: lineItem.get("slug"),
  thumbnailSrc: lineItem.get("image"),
  unitPrice: lineItem.get("discountedTotal"),
})

import { toDecamelizedJSON } from "highline/utils/immutable_helper"
import { decamelizeKeys } from "humps"
import { get, post, del } from "highline/api/v2_client"
import recaptchaGuard from "highline/utils/recaptcha_guard"

/*
  Returns all the saved wallet records for a user
*/
export const show = (authToken) => {
  return get(
    "/wallet",
    {},
    {
      "X-Authentication-Token": authToken,
    }
  )
}

/*
  Add a new wallet record
  Expected payload should be in the following format

  {
    "credit_card": {
      "save_credit_card_in_wallet": true,
      "set_new_card_as_default": true,
      "card_details": {
        "nonce": "fake-valid-nonce",
        "name": "11111",
        "bill_address": {
          "first_name": "name1",
          "last_name": "lastname1",
          "address1": "some address1",
          "address2": "",
          "phone": "333-333-3333",
          "city": "some city",
          "region_code": "NY",
          "country_code": "US",
          "postal_code": "33333"
        }
      }
    }
  }
*/
export const create = recaptchaGuard(
  "createPaymentMethod",
  async (authToken, wallet, recaptchaToken) =>
    post(
      "/wallet",
      {
        credit_card: toDecamelizedJSON(wallet),
      },
      {
        "X-Authentication-Token": authToken,
        "X-Recaptcha-Token": recaptchaToken,
      }
    )
)

export const validateSecurityCode = (authToken, nonce, id) => {
  const formattedWalletData = decamelizeKeys({
    id,
    securityCodeValidationNonce: nonce,
  })

  return post(
    "/wallet/validate_security_code",
    {
      credit_card: formattedWalletData,
    },
    {
      "X-Authentication-Token": authToken,
    }
  )
}

/*
 Deletes a wallet record by ID
*/
export const destroy = (authToken, id) => {
  return del(
    "/wallet",
    {},
    {
      credit_card: { id },
    },
    {
      "X-Authentication-Token": authToken,
    }
  )
}

import * as CheckoutApi from "highline/api/checkout_api"
import * as CartApi from "highline/api/cart_api"
import ActionTypes from "highline/redux/action_types"
import { regionsUpdated } from "highline/redux/actions/location_actions"
import {
  orderFatalErrorReceived,
  checkoutStepCompleted,
} from "highline/redux/actions/order_actions"
import {
  getAddressForRequest,
  getBillingInformationForRequest,
  getNonceForRequest,
  getGiftCardNonceForRequest,
} from "highline/redux/helpers/checkout_helper"
import { handleAffirmCheckoutProcess, createAffirmCheckoutObject } from "highline/utils/affirm"
import {
  EXPIRY_DATE_DELIMITER,
  EXPIRY_DATE_YEAR_PREFIX,
} from "highline/utils/billing_information_helper"

export const billingExistingCreditCardToggled = () => ({
  type: ActionTypes.BILLING_EXISTING_CREDIT_CARD_TOGGLED,
})

export const billingInformationCreditCardInputChanged = (name, rawValue) => ({
  name,
  type: ActionTypes.BILLING_INFORMATION_CREDIT_CARD_INPUT_CHANGED,
  rawValue,
})

export const billingInformationExpiryDateInputChanged = (formattedValue) => {
  const [month, year] = formattedValue.split(EXPIRY_DATE_DELIMITER)

  return {
    type: ActionTypes.BILLING_INFORMATION_EXPIRY_DATE_INPUT_CHANGED,
    month,
    year: EXPIRY_DATE_YEAR_PREFIX + year,
  }
}

export const billingInformationInputChanged = (name, value) => ({
  name,
  type: ActionTypes.BILLING_INFORMATION_INPUT_CHANGED,
  value,
})

export const billingInformationAddressInputChanged = (name, value) => ({
  name,
  type: ActionTypes.BILLING_INFORMATION_ADDRESS_INPUT_CHANGED,
  value,
})

export const billingInformationCheckboxChanged = (name, value) => ({
  name,
  type: ActionTypes.BILLING_INFORMATION_CHECKBOX_CHANGED,
  value,
})

export const billingInformationRequestStarted = () => ({
  type: ActionTypes.BILLING_INFORMATION_REQUEST_STARTED,
})

export const billingInformationRequestCompleted = () => ({
  type: ActionTypes.BILLING_INFORMATION_REQUEST_COMPLETED,
})

export const billingInformationAddSucceeded = (order, paymentMethodType) => ({
  order,
  paymentMethodType,
  type: ActionTypes.BILLING_INFORMATION_ADD_SUCCEEDED,
})

export const billingInformationAddFailed = (error) => ({
  error,
  type: ActionTypes.BILLING_INFORMATION_ADD_FAILED,
})

export const billingInformationPaymentTypeChanged = (value) => ({
  type: ActionTypes.BILLING_INFORMATION_PAYMENT_TYPE_CHANGED,
  value,
})

export const billingInformationReviewNavigateClicked = () => ({
  type: ActionTypes.BILLING_INFORMATION_REVIEW_NAVIGATE_CLICKED,
})

export const billingInformationStateReset = () => ({
  type: ActionTypes.BILLING_INFORMATION_STATE_RESET,
})

export const billingInformationZipInputChanged = (name, value) => ({
  name,
  type: ActionTypes.BILLING_INFORMATION_ZIP_INPUT_CHANGED,
  value,
})

export const billingInformationCountryUpdated = (country, postalCodeLabel, regionLabel) => ({
  country,
  postalCodeLabel,
  regionLabel,
  type: ActionTypes.BILLING_INFORMATION_COUNTRY_UPDATED,
})

export const billingInformationRegionUpdated = (region) => ({
  region,
  type: ActionTypes.BILLING_INFORMATION_REGION_UPDATED,
})

// PayPal Click is used for Segment Tracking
export const paypalButtonClicked = () => ({
  type: ActionTypes.PAYPAL_BUTTON_CLICKED,
})

// Affirm Click is used for Segment Tracking
export const affirmCheckoutModalOpened = () => ({
  type: ActionTypes.AFFIRM_CHECKOUT_MODAL_OPENED,
})

export const affirmCheckoutModalClosed = () => ({
  type: ActionTypes.AFFIRM_CHECKOUT_MODAL_CLOSED,
})

export const affirmCheckoutFailed = (errorMessage) => ({
  type: ActionTypes.AFFIRM_CHECKOUT_FAILED,
  errorMessage,
})

export const affirmCheckoutSucceeded = () => ({
  type: ActionTypes.AFFIRM_CHECKOUT_SUCCEEDED,
})

export const billingAddPaypalFailed = () => ({
  type: ActionTypes.BILLING_ADD_PAYPAL_FAILED,
})

export const securityCodeValidated = () => ({
  type: ActionTypes.SECURITY_CODE_VALIDATED,
})

export const securityCodeValidationAttempted = (wasSuccess) => ({
  type: ActionTypes.SECURITY_CODE_VALIDATION_ATTEMPTED,
  wasSuccess,
})

export const securityCodeValidationFormShown = () => ({
  type: ActionTypes.SECURITY_CODE_VALIDATION_FORM_SHOWN,
})

export const inlineValidation = (name, errorMessage) => (dispatch) => {
  // PostalCode/zipcode is used inconsistently between flatiron/highline
  // Resolving this would require significant refactoring
  const actualName = name === "postalCode" ? "zipcode" : name
  if (errorMessage) {
    dispatch(inlineValidationFailure(actualName, errorMessage))
  } else {
    dispatch(inlineValidationSuccess(actualName))
  }
}

export const inlineValidationFailure = (name, errorMessage) => ({
  errorMessage,
  name,
  type: ActionTypes.INLINE_VALIDATION_FAILED,
})

export const inlineValidationSuccess = (name) => ({
  name,
  type: ActionTypes.INLINE_VALIDATION_SUCCESS,
})

export const removeGiftCardRequestStarted = () => ({
  type: ActionTypes.REMOVE_GIFT_CARD_REQUEST_STARTED,
})

export const removeGiftCardRequestFailed = () => ({
  type: ActionTypes.REMOVE_GIFT_CARD_REQUEST_FAILED,
})

export const removeGiftCardRequestSucceeded = (order) => ({
  order,
  type: ActionTypes.REMOVE_GIFT_CARD_REQUEST_SUCCEEDED,
})

export const billingInformationCountryAsync = (code) => async (dispatch, getState) => {
  const countryWithRegions = getState()
    .getIn(["location", "countries"])
    .filter((country) => country.get("value") === code)
    .get(0)

  const country = {
    code,
    name: countryWithRegions.get("label"),
  }

  const { postalCodeLabel, regionLabel, regions } = countryWithRegions.toJS()

  dispatch(billingInformationCountryUpdated(country, postalCodeLabel, regionLabel))
  dispatch(regionsUpdated(regions))
}

export const billingInformationRegionAsync = (code) => async (dispatch, getState) => {
  const region = {
    code,
    name: getState()
      .getIn(["location", "regions"])
      .filter((region) => region.get("value") === code)
      .getIn([0, "label"]),
  }

  dispatch(billingInformationRegionUpdated(region))
}

export const addBillingInformationAsync = () => async (dispatch, getState) => {
  dispatch(billingInformationRequestStarted())

  const billingInformation = getState().get("billingInformation")
  const order = getState().get("order")
  const number = order.get("number")
  const token = order.get("token")
  const isSameAsShippingAddress = billingInformation.get("isSameAsShippingAddress")
  const address = isSameAsShippingAddress ? order.get("address") : billingInformation.get("address")
  const addressForRequest = getAddressForRequest(address)
  const isDefault = billingInformation.get("isDefault")
  const isInWallet = billingInformation.get("isInWallet")
  const name = billingInformation.get("name")

  const nonce = getNonceForRequest(billingInformation)

  const billingInformationForRequest = getBillingInformationForRequest(
    "adyen",
    isInWallet,
    isDefault,
    nonce,
    name,
    addressForRequest
  )

  if (number && token) {
    try {
      const response = await CheckoutApi.addBillingInformation(
        number,
        token,
        billingInformationForRequest
      )
      const order = response.data.get("cart")
      dispatch(billingInformationAddSucceeded(order, "card"))
      dispatch(checkoutStepCompleted())
      dispatch(billingInformationStateReset())
    } catch (error) {
      if ([401, 404].includes(error.status)) {
        return dispatch(orderFatalErrorReceived(error.status, error.data, number))
      } else {
        dispatch(billingInformationAddFailed(error.data))
      }
    }
  }
  return dispatch(billingInformationRequestCompleted())
}

export const addGiftCardAsync = (giftCardNumber, securityCode) => async (dispatch, getState) => {
  dispatch(billingInformationRequestStarted())

  const orderNumber = getState().getIn(["order", "number"])
  const token = getState().getIn(["order", "token"])

  if (orderNumber && token) {
    try {
      const nonce = getGiftCardNonceForRequest(giftCardNumber)
      const response = await CheckoutApi.addGiftCard(orderNumber, token, nonce, securityCode)
      const order = response.data.get("cart")
      dispatch(billingInformationAddSucceeded(order, "giftCard"))
    } catch (error) {
      if ([401, 404].includes(error.status)) {
        return dispatch(orderFatalErrorReceived(error.status, error.data, orderNumber))
      } else {
        dispatch(billingInformationAddFailed(error.data))
      }
    }
  }
  return dispatch(billingInformationRequestCompleted())
}

export const billingInformationNameSet = (name) => ({
  name,
  type: ActionTypes.BILLING_INFORMATION_NAME_SET,
})

export const billingInformationPageLoaded = () => async (dispatch, getState) => {
  const state = getState()
  if (
    state.getIn(["billingInformation", "name"]) ||
    !state.getIn(["shippingInformation", "firstName"]) ||
    !state.getIn(["shippingInformation", "lastName"])
  )
    return
  const name = `${state.getIn(["shippingInformation", "firstName"])} ${state.getIn([
    "shippingInformation",
    "lastName",
  ])}`
  dispatch(billingInformationNameSet(name))
}

export const affirmCheckoutAsync = () => async (dispatch, getState) => {
  const state = getState()
  const checkoutPayload = createAffirmCheckoutObject(state)
  const orderNumber = state.getIn(["order", "number"])
  dispatch(handleAffirmCheckoutProcess(checkoutPayload, orderNumber))
}

export const removeGiftCardAsync = (giftCardId) => async (dispatch, getState) => {
  const orderNumber = getState().getIn(["order", "number"])
  const token = getState().getIn(["order", "token"])
  if (!(orderNumber && token)) return

  dispatch(removeGiftCardRequestStarted())

  try {
    const response = await CartApi.removeGiftCard(orderNumber, token, giftCardId)
    const order = response.data.get("cart")
    dispatch(removeGiftCardRequestSucceeded(order))
  } catch (error) {
    dispatch(removeGiftCardRequestFailed())
  }
}
